import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'
import mainHeader from '../../images/solutions/bpmMain.jpg'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { FaqSection } from '../../components/faq'
import clsx from 'clsx'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { bpmList } from '../../utils/customersList'

const faqItems = [
  {
    question: 'O que é preciso para iniciar o projeto de consultoria?',
    answer:
      'Primeiramente, o desejo de mudar. Todo projeto de Consultoria BPM impacta nos processos da empresa, portanto requer engajamento das pessoas para que as mudanças aconteçam. O apoio da alta direção também é fundamental para o sucesso do projeto. Em termos práticos, a equipe interna precisa ter disponibilidade para levantamento de informações e para implementar as ações de melhoria junto à consultoria.',
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Depende do nível de maturidade de gestão da empresa. Projetos de implementação da Gestão por Processos comumente demandam de 03 a 12 meses de execução. O cronograma depende de algumas variáveis, como o escopo do projeto, quantidade de processos envolvidos no mapeamento e melhoria, quantidade de funcionários e disponibilidade da equipe interna para trabalhar com a consultoria.',
  },
  {
    question: 'Como solicitar um orçamento para um projeto de consultoria?',
    answer:
      'Informe seus dados no formulário acima para receber o contato de um Especialista em Processos. Um especialista retornará o contato para coletar informações, através de uma reunião de diagnóstico. Mais do que um orçamento, é necessário entender qual o momento atual da empresa, quais são os principais desafios, para que então possamos oferecer a melhor solução.',
  },
  {
    question: 'Como saber se o projeto trará ROI para a minha empresa?',
    answer:
      'Projetos de consultoria são investimentos, e não despesas, portanto devem proporcionar ganhos para a organização. Em cada melhoria identificada, nossos Especialistas em Melhoria Contínua calculam os ganhos quantitativos e qualitativos esperados para cada mudança a ser implementada. Analisamos as principais variáveis dos processos para encontrar melhorias de valor, relacionadas a pessoas, riscos e oportunidades, lógica do processo, sistemas de informação e indicadores de desempenho.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Planejamento',
    description: (
      <>
        A fase do planejamento é um processo comercial. Neste momento ainda não
        houve a contratação do produto. O objetivo desta etapa é alinhar todas
        as expectativas do projeto e os objetivos junto à contratante.
      </>
    ),
  },
  {
    count: 2,
    title: 'Mapeamento',
    description: (
      <>
        A etapa do mapeamento do processo é o momento em que identificamos as
        dores dos colaboradores da empresa cliente, quanto às atividades do dia
        a dia que levaram o processo a chegar à estrutura atual.
      </>
    ),
  },
  {
    count: 3,
    title: 'Análise e Melhoria',
    description: (
      <>
        Nesta etapa iremos analisar os processos diante de suas variáveis:
        pessoas, riscos e controles internos, lógica do fluxo do processo,
        sistemas de informação e indicadores de desempenho para sugerir
        melhorias. Será criada a nova versão de processo.
      </>
    ),
  },
  {
    count: 4,
    title: 'Implementação',
    description: (
      <>
        Muito importante para a melhoria não ficar apenas na teoria. Utilizamos
        técnicas de GMO do instituto HCMI para engajar e "puxar" as mudanças
        para a empresa realmente implantar o que foi aprovado. Com reuniões
        periódicas unem-se os agentes das mudanças para reportarem os avanços do
        projeto.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `Mapeamento e Melhoria de Processos`,
    description: (
      <>
        Atuamos com base nas boas práticas de mercado, começando pelo mapeamento
        de processo AS IS, sugerimos melhorias, desenhamos o processo TO BE e
        implantamos as mudanças.
      </>
    ),
  },
  {
    title: `Escritório de Processos`,
    description: (
      <>
        Fazemos a implantação do Escritório de Processos, responsável por apoiar
        toda a organização com metodologias, técnicas, ferramentas, e
        treinamento em gestão por processos, desde o nível operacional ao
        estratégico.
      </>
    ),
  },
  {
    title: `Treinamento BPM`,
    description: (
      <>
        Conceitos BPM baseados no CBOK 4.0, boas práticas e frameworks de
        processos. Entenda o modelo organizacional para identificar, desenhar,
        executar, documentar, medir, monitorar, controlar e melhorar processos
        de negócio.
      </>
    ),
  },
  {
    title: `Aderência de Sistemas`,
    description: (
      <>
        Apoiamos na escolha do melhor sistema de gestão (ERP ou CRM) para o seu
        negócio. Avaliamos a aderência do sistema aos processos, relação
        custo-benefício, provas de conceito. Seja assertivo na tomada de decisão
        para migração ou aquisição de novo sistema.
      </>
    ),
  },
]

const BpmPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Gestão por processos de negocio BPM" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Mapeamento, melhoria e estruturação de novos processos</h1>
              <p>
                Conheça as nossas soluções em BPM (Gestão por Processos de
                Negócio) para mapear, documentar, monitorar e melhorar processos
                de negócio. Integre a estratégia da organização às expectativas
                de clientes para alavancar resultados.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Buscar a melhor solução para seu processo, seja apenas uma
                  padronização ou ainda mudanças mais sofisticadas como RPA e
                  IA.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection items={methodologyItems} />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={bpmList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Especialistas certificados</h2>
                  <p>
                    Em gestão por processos (ABPMP, OCEB) e gestão de projetos
                    (PMI)
                  </p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Quantificando e qualificando ganhos de projeto </p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default BpmPage
